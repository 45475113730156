<template>
  <div class="patentDetali-container">
    <Top></Top>
    <Fix></Fix>
    <div class="patentDetali">
      <!-- 面包屑 -->
      <div class="patentDetail-crumbs">
        <el-breadcrumb class="bread-card-crumb" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">
            首页
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/patent'}">专利服务</el-breadcrumb-item>
          <el-breadcrumb-item>{{this.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 价钱 -->
      <div class="patentDetail-price">
        <div class="price-left">
          <p>{{this.type_title}}</p>
          <div class="left-one">
            <span>服务价格：</span>
            <span>￥{{this.price}}</span>
          </div>
          <div class="left-two">
            <span>服务类型：</span>
            <div class="left-two-rigth">
              <div class="left-two-rigth-dev" v-for="(v,k) in type" :key="k" @click="changeType(v.goodsName,v.price,v.goodsDescribe,v.webBusinessProcess,v.imgBottom,v.goodsCode)" v-bind:class="{colorChanged:v.goodsName==dynamic}">
                {{v.goodsName}}
              </div>
            </div>
          </div>
          <div style="clear:both;"></div>
          <div class="left-three">
            <span>购买数量：</span>
            <span @click="miu1">-</span>
            <input v-model="value1" type="text" disabled  @input="add2"/>
            <span @click="add1">+</span>
          </div>
          <div class="left-four">
            <span>结算金额：</span>
            <span>￥{{this.total}}</span>
          </div>
          <div class="left-five">
            <!-- <span @click="tobuy1">检测办理</span> -->
            <span @click="tobuy">立即购买</span>
            <span @click="addCart">加入购物车</span>
            <span @click="message_show">咨询留言</span>
            <!-- <span @click="message_show">咨询留言</span> -->
          </div>
        </div>
        <div class="price-right"><img :src="this.pic" alt=""></div>
      </div>
      <!-- 后台渲染的图片 -->
      <div class="patentDetail-images">
        <img :src="this.bigimg" alt="">
      </div>

      <!-- 留言弹框 -->
      <div class="add-message" v-show="messageShow">
        <div class="message-form">
          <span class="center">欢迎留言</span>
          <img :src="require('/static/images/cha.png')" alt="" @click="cha">
          <div class="form-div">
            <div class="recevie-people">
              <span>联&nbsp;&nbsp;系&nbsp;&nbsp;人</span>
              <input type="text" v-model="people">
            </div>
          <div class="div2">
            <span>联系电话</span>
            <input class="deal" type="text" v-model="moblie" maxlength="14">
            <div class="red">{{mobileTip}}</div>
          </div>
          <div class="div3">
            <span>留&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;言</span>
            <textarea name="" id="" class="ly" v-model="comment"></textarea>
          </div>
          <p>电话咨询：400-630-2718</p>
          <div class="edit" @click="edit()">提交</div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from "../ui/top.vue";
import Foot from "../ui/foot.vue";
import Fix from '../ui/fix.vue';
export default {
  name: "patentDetail",
  components: {
    Top,
    Foot,
    Fix
  },
  data() {
    return {
      value1: 1,
      title:'',
      type:[],
      // 控制高亮
      dynamic:'',
      // 服务类型价格
      price:'',
      // 服务类型名称
      type_title:'',
      // 结算总金额
      total:'',
      // 小图
      pic:'',
      code:'',
      onpresscTime:0,
      bigimg:'',
      // 添加购物车的数据
      cartListObj: {
            userCode: '',
            //商品code
            goodsCode: '',
            //商品名字
            goodsName: '',
            //商品价格
            orderPrice: ''
        },
      // 留言
      people:'',
      moblie:'',
      comment:'',
      mobileTip:'',
      messageShow:false,
      analysis_show:false,
      consultant:{}
    };
  },
  watch:{
    '$route': 'getData',
    'moblie'(newValue){
      let reg=/^[1][3,4,5,7,8,9][0-9]{9}$/;
      if(newValue ==''){
                    return this.mobileTip='请输入手机号码';
                }
                if(newValue.length!=11||!reg.test(newValue)){
                    return this.mobileTip='手机号格式不正确';
                }
                return this.mobileTip='';
    }
  },
  methods: {
    //弹框
    tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:2000
          })
    },
    // 获取数据
    getData() {
      this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
            goodsLevel:'0',
            userCode:this.$cookie.getCookie('u_id'),
            goodsType:'2'
      }).then(res=>{
        // console.log(res)
       if ( res.data.code == '200') {
        var arr = [];
        arr = res.data.data;
        const agent = window.localStorage.getItem('UserAgent')
        for(var i = 0;i<arr.length;i++){
              for(var j =0;j<arr[i].goodsInfoDTOList.length;j++){
                var _this=this;
                  if(agent == 'HT'){
                  _this.$set(arr[i].goodsInfoDTOList[j], 'price', Number(arr[i].goodsInfoDTOList[j].proxyPrice)+100);
                  }else{
                    _this.$set(arr[i].goodsInfoDTOList[j], 'price', Number(arr[i].goodsInfoDTOList[j].proxyPrice));
                  }
              }
              }
              if(this.$route.query.secondName == "0"){
                        this.title= this.$route.query.parentName;
                        for(var i = 0;i<arr.length;i++){
                            if(this.$route.query.parentName == arr[i].goodsName){
                              this.type = arr[i].goodsInfoDTOList;
                              this.type_title = this.type[0].goodsName;
                              this.desc = this.type[0].goodsDescribe;
                              this.price = this.type[0].price;
                              this.total = this.price*this.value1;
                              this.dynamic = this.type[0].goodsName;
                              this.bigimg = this.type[0].webBusinessProcess;
                              this.pic = this.type[0].imgBottom;
                              this.code = this.type[0].goodsCode;
                            }
                        }
                      }else{
                          for(var i=0;i<arr.length;i++){
                            for(var j =0;j<arr[i].goodsInfoDTOList.length;j++){
                              if(this.$route.query.secondName== arr[i].goodsInfoDTOList[j].goodsName ){
                                this.type = arr[i].goodsInfoDTOList;
                                this.title = arr[i].goodsName;
                                this.type_title = arr[i].goodsInfoDTOList[j].goodsName;
                                this.desc = arr[i].goodsInfoDTOList[j].goodsDescribe;
                                this.price = arr[i].goodsInfoDTOList[j].price;
                                this.total = this.price*this.value1;
                                this.dynamic = arr[i].goodsInfoDTOList[j].goodsName;
                                this.bigimg = arr[i].goodsInfoDTOList[j].webBusinessProcess;
                                this.pic = arr[i].goodsInfoDTOList[j].imgBottom;
                                this.code = arr[i].goodsInfoDTOList[j].goodsCode;
                              }
                            }
                          }
                      }
        }
      })
    },
    changeType(val1,val2,val3,val4,val5,val6){
        this.dynamic = val1;
        this.type_title = val1;
        this.price = val2;
        this.total = this.price*this.value1;
        this.desc = val3;
        this.bigimg = val4;
        this.pic = val5;
        this.code = val6;
      },
    // 增加
    add1(){
          this.value1++;
            this.total = this.price*this.value1;
    },
    // 减少
    miu1(){
        if(this.value1>1){
          this.value1--;
            this.total = this.price*this.value1;
        }
    },
    // 输入
    add2() {
      this.total = this.price*this.value1;
    },
    // 添加购物车
    addCart(){
      if(this.$cookie.getCookie('u_id')){
            if(this.value1>=1){
                var list = [];
                this.cartListObj.userCode = this.$cookie.getCookie('u_id');
                this.cartListObj.goodsName = this.type_title;
                this.cartListObj.goodsCode = this.code;
                this.cartListObj.orderPrice = this.price;
                 for (let i = 0; i < this.value1; i++) {
                  list.push(this.cartListObj)
                };
                this.$http.post(this.GLOBAL.new_url+'/trademark/service/cart/addCart',{
                  cartType:'2',
                  cartVOList:list,
                  typeName:this.title
              },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                  if(res.data.code == "200"){
                    this.tip('success',"添加购物车成功");
                  }else{
                    this.tip('error',res.data.msg)
                  }
              })
              // console.log(list)
            }
            
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }
    },
    // 立即办理
    tobuy() {
      if(this.$cookie.getCookie('u_id')){
          var list = [];
      var detail = {
        goodsType: "2",
        goodsCode: this.code,
			goodsName: this.title,
			orderType: this.type_title,
			serviceTypeName: this.type_title
		}
            if(this.value1>=1){
              for (let i = 0; i < this.value1; i++) {
                        list.push(detail)
                      };
            }
            // console.log(list)
            this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/insertOrder',{
            list:list
          },{
                    headers:{
                      'X-User-Code':this.$cookie.getCookie('u_id'),
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
            // console.log(res)
              if(res.data.code == "200"){
                  var a = '';
                  for(var i =0;i<res.data.data.length;i++){
                            a+=res.data.data[i].orderCode+','
                    }
                    a =  a.substring(0,a.length-1);
                  this.$router.push({
                      path:'/checkOrder',
                      query:{
                      order:a
                      }
                  })
              }else{
                this.tip('error',res.data.msg)
              }
          })
        }else{
          this.$router.push({
            path:'/login',
            query:{
              good:0
            }
          })
        }
    },
    tobuy1(){
      alert('此业务正在开通中')
    },
    // 打开
    message_show(){
      if(this.$cookie.getCookie('u_id')){
        this.messageShow = true
        }else{
        this.$router.push({
            path:'/login',
            query:{
            good:0
            }
        })
      }

    },
    // 关闭
    cha(){
      this.messageShow = false
    },
    // 提交
    edit() {
      if(this.people == '') {
        this.tip('error','请填写联系人')
        return false
      }else if (this.people.split(" ").join("").length == 0){
        this.tip('error','请输入文字')
        return false
      }else if (this.moblie == ''){
        this.tip('error','请填写联系电话')
        return false
      }else if(this.mobileTip=='手机号格式不正确'){
        return false
      }else if (this.moblie.split(" ").join("").length == 0){
        this.tip('error','请输入数字')
        return false
      }else if (this.comment == ''){
        this.tip('error','请留言')
        return false
      }else if (this.comment.split(" ").join("").length == 0){
        this.tip('error','请输入文字')
        return false
      }else {
        this.$http.post(this.GLOBAL.new_url+'/user/service/insertConsultData',{
          userCode:this.$cookie.getCookie('u_id'),
          userCall:this.people,
          userMobile:this.moblie,
          message:this.comment,
          empCode:this.consultant.empCode,
          adviseNickName:this.consultant.adviseNickName,
          consultType:2,
          serverType:this.type_title
          }).then( res => {
          // console.log(res)
          if(res.data.code == '200') {
            this.tip('success','提交成功,客服会联系您')
            this.messageShow = false
          }
        })
      }
    }
  },
  mounted(){
    this.getData()
    if(this.$cookie.getCookie('u_id')){
        this.$http.post(this.GLOBAL.new_url+'/user/service/getAdviserList',{
                        userCode:this.$cookie.getCookie('u_id'),
                        allAdvise:"1"
                    },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                        if(res.data.code == "200"){
                            for(var i=0;i<res.data.data.length;i++){
                                if(this.$cookie.getCookie('consultant') == res.data.data[i].empCode){
                                    this.consultant = res.data.data[i]
                                    // console.log(this.consultant)
                                }
                            }
                            
                        }
              
                    })
      }
  }
};
</script>

<style scoped>
/* 公共样式 */
ul,
li {
  list-style: none;
}
/* 面包屑 */
.patentDetail-crumbs {
  width: 100%;
  height: 40px;
  background-color: #f8f8f8;
}
.bread-card-crumb {
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  text-align: center;
  padding-top: 12px;
  box-sizing: border-box;
}
.bread-card-crumb > img {
  width: 18px;
  height: 16px;
}
/* 价钱 */
.patentDetail-price {
  width: 1200px;
  height: 420px;
  background-color: #fff;
  margin: 0 auto;
  margin-bottom: 120px;
}
.patentDetail-price > .price-left {
  width: 600px;
  height: 360px;
  float: left;
  padding-top: 20px;
  box-sizing: border-box;
}
.patentDetail-price > .price-left > p {
  font-family: "PingFangSC";
  font-size: 22px;
  font-weight: 500;
}
.patentDetail-price > .price-left > .left-one {
  margin-top: 18px;
}
.patentDetail-price > .price-left > .left-one > span:nth-child(1) {
  font-family: "PingFangSC";
  font-size: 18px;
  color: #434343;
}
.patentDetail-price > .price-left > .left-one > span:nth-child(2) {
  font-family: "PingFangSC";
  font-size: 18px;
  color: #ff1d1d;
}
.patentDetail-price > .price-left > .left-two {
  margin-top: 28px;
}
.patentDetail-price > .price-left > .left-two > span {
  font-family: "PingFangSC";
  font-size: 18px;
  color: #434343;
}
.patentDetail-price > .price-left > .left-two > .left-two-rigth {
  width: 462px;
  float: right;
  margin-right: 44px;
  margin-top: -6px;
}
.patentDetail-price
  > .price-left
  > .left-two
  > .left-two-rigth
  > .left-two-rigth-dev {
  width: 220px;
  float: left;
  text-align: center;
  height: 33px;
  border-radius: 4px;
  line-height: 33px;
  background-color: #cdcdcd;
  margin-right: 9px;
  margin-bottom: 5px;
  font-family: "PingFangSC";
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.left-three > span:nth-child(1) {
  font-family: "PingFangSC";
  font-size: 18px;
  color: #434343;

}
.left-three > span:nth-child(2) {
  width: 26px;
  height: 26px;
  border-radius: 2px;
  background-color: #f1f1f1;
  display: inline-block;
  text-align: center;
  font-family: "PingFangSC";
  font-size: 16px;
  cursor: pointer;
}
.left-three > input {
  width: 34px;
  height: 22px;
  outline: none;
  border: none;
  text-align: center;
  padding: 0 5px;
  vertical-align: middle;
}
.left-three > input[disabled] {
  background-color: #fff;
}
.left-three > span:nth-child(4) {
  width: 26px;
  height: 26px;
  border-radius: 2px;
  background-color: #f1f1f1;
  display: inline-block;
  text-align: center;
  font-family: "PingFangSC";
  font-size: 18px;
  cursor: pointer;
}
.patentDetail-price > .price-left > .left-four {
  margin-top: 22px;
}
.patentDetail-price > .price-left > .left-four > span:nth-child(1) {
  font-family: "PingFangSC";
  font-size: 18px;
  color: #434343;
}
.patentDetail-price > .price-left > .left-four > span:nth-child(2) {
  font-family: "PingFangSC";
  font-size: 18px;
  color: #ff1d1d;
}
.patentDetail-price > .price-left > .left-five {
  margin-top: 15px;
}
.patentDetail-price > .price-left > .left-five > span{
  width: 160px;
  height: 41px;
  line-height: 41px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  border-width: 1px;
  background-color: #f79000;
  color: #fff;
  background-clip: content-box, border-box;
  font-family: "PingFangSC";
  font-size: 18px;
  color: #fff;
  margin-right: 26px;
  cursor: pointer;
}
.patentDetail-price > .price-left > .left-five > span:nth-child(2) {
  background-color: #e3e3e3;
  color: #f87604;
}
.patentDetail-price > .price-right {
  width: 560px;
  height: 360px;
  float: right;
}
.patentDetail-price > .price-right >img {
  width: 560px;
  height: 360px;
  margin-top: 20px;
}
.colorChanged {
  background-color: #EB5E00 !important;
}
.patentDetail-images {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 48px;
}
/* 留言 */
   .add-message{
   background: rgba(0,0,0, 0.5);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
 .message-form{
   width: 582px;
   height: 474px;
   background: #ffffff;
   position: absolute;
   top:50%;
   left: 50%;
   z-index: 30;
   transform: translate(-50%,-50%);
 }
 .message-form>img{
   width: 24px;
   height: 24px;
   position: absolute;
   right:20px;
   top:20px;
   cursor: pointer;
 }
 .form-div{
   position: absolute;
   top:81px;
   left: 96px;
 }
 .form-div>div{
   margin-bottom: 30px;
 }
 .form-div>div>span{
   font-size: 14px;
   margin-right: 16px;
   color: #333333;
 }
 .form-div>div>input{
    width: 290px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    outline: none;
    padding-left: 10px
 }
 .select1{
   width: 96px;
  height: 32px;
  border-radius: 4px;
  margin-right: 2px;
 }
 .count{
  width: 80px;
  height: 36px;
  display: inline-block;
  border-radius: 4px;
  background-color:#EB5E00;
  margin: 0 auto;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
 }
 .edit{
   width: 80px;
  height: 36px;
  display: inline-block;
  border-radius: 4px;
  background-color:#EB5E00;
  margin: 0 auto;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 165px;
 }
.div3 > span {
  display: inline-block;
  vertical-align: top;
}
.div2 > span {
  margin-right: 18px !important;
}
 textarea {
    width: 300px;
    height: 96px;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    outline: none;
    padding: 10px;
    resize: none;
    box-sizing: border-box;
    margin-left: 7px;
 }
 .add-message > .message-form > .form-div > p {
   margin: 0 auto;
   color: #EB5E00;
   font-size: 12px;
   font-weight: normal;
   margin-bottom: 30px;
   margin-left: 146px;
 }
 .center {
   position: absolute;
   top: 30px;
   margin-left:276px;
 }
 .red {
   margin-left: 80px;
  color: red;
  height: 30px;
 }
 .div2 {
  margin-bottom: 0px !important;
 }
</style>
